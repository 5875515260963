import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  TabBox,
  ScrollTabs,
  LoadingBox,
  LoadingCircularProgress,
  TabLabel,
} from './style';
import { useNavigate } from 'react-router-dom';
import ErrorMessageCard from '../ErrorMessageCard';
import { getStore } from 'src/mobx-store';

const PageTab = ({
  tabList,
  helmetList = null,
  onChangeTabName = undefined,
  renderingByIndex,
  rerenderingDeps,
  prefix = '',
  interpolation = null,
  useScroll = true,
}) => {
  const { utilStore } = getStore();
  const {
    previousRouting,
    isTabIndexKeep,
    setPreviousRouting,
    setIsTabIndexKeep,
  } = utilStore;
  const navigate = useNavigate();
  const [selectedTabIndex, setSelectedTabIndex] = useState();
  const [context, setContext] = useState(null);

  const { t } = useTranslation();

  const displayContext = (selectedTabIndex) => {
    setContext(renderingByIndex(selectedTabIndex));
    if (onChangeTabName) onChangeTabName(helmetList[selectedTabIndex]);
  };

  useEffect(() => {
    /* 탭이 마운트 된 경우
      1. 이전에 언마운트 됐던 url이 # 뒤에 번호가 붙은 상태였지만 라우팅이 다른 경우 인덱스를 0으로 초기화
      2. 그렇지 않은 경우 이전 pageIndex로 세팅
    */

    let initTabIndex = Number(localStorage.getItem('pageTabIndex'));
    const currentRouting = window.location.pathname.split('/')?.[2];

    if (isTabIndexKeep && previousRouting !== currentRouting) {
      initTabIndex = 0;
    }

    setSelectedTabIndex(initTabIndex);
    displayContext(initTabIndex);

    return () => {
      /* 탭이 언마운트 된 경우
        1. url의 # 뒤에 번호가 붙은 경우 해당 값으로 로컬 스토리지 값 세팅
        2. 그렇지 않은 경우 0으로 세팅
      */

      const forceKeepIndex = window.location.href.split('#')?.[1];
      const unmountRouting = window.location.pathname.split('/')?.[2];
      setPreviousRouting(unmountRouting);

      if (forceKeepIndex) {
        localStorage.setItem('pageTabIndex', forceKeepIndex);
        setIsTabIndexKeep(true);
      } else {
        localStorage.setItem('pageTabIndex', 0);
        setIsTabIndexKeep(false);
      }
    };
  }, []);

  useEffect(() => {
    if (selectedTabIndex !== -1 && context) {
      displayContext(selectedTabIndex);
    }
  }, [selectedTabIndex, rerenderingDeps]);

  const onTabClickHandler = (tabIndex) => {
    setSelectedTabIndex(tabIndex);
    if (helmetList && tabIndex === Number(localStorage.getItem('pageTabIndex')))
      navigate(0);
    localStorage.setItem('pageTabIndex', tabIndex);
  };

  return selectedTabIndex === -1 ? (
    <ErrorMessageCard
      code="E400"
      buttonText="이전 페이지"
      mainContent="존재하지 않는 페이지입니다."
      clickEvent={() => {
        window.history.back();
      }}
    />
  ) : context ? (
    <>
      <TabBox>
        {tabList ? (
          <ScrollTabs
            value={selectedTabIndex}
            variant={useScroll ? 'scrollable' : 'fullWidth'}
            scrollButtons={useScroll ? 'auto' : false}
          >
            {tabList.map((tab, tabIndex) => {
              return (
                <TabLabel
                  label={
                    interpolation
                      ? t(prefix + interpolation.key, {
                          [interpolation.value]: t(tab),
                        })
                      : t(prefix + tab)
                  }
                  key={tab}
                  onClick={() => onTabClickHandler(tabIndex)}
                />
              );
            })}
          </ScrollTabs>
        ) : null}
      </TabBox>
      {context}
    </>
  ) : (
    <LoadingBox>
      <LoadingCircularProgress />
    </LoadingBox>
  );
};

export default PageTab;
