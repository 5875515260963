export const GetDataConfig = {
  kpi: [],
};

export const ParameterInfo = {
  kpi: {
    HPD: {
      title: '시간당 생산량',
      unit: 'kg',
      // icon: <DeviceThermostatIcon />,
    },
    DPD: {
      title: '일 생산량',
      unit: 'kg',
      // icon: <WaterDropIcon />,
    },
  },
  sensors: {
    HPD: {
      title: '시간당 생산량',
      unit: 'kg',
      // icon: <DeviceThermostatIcon />,
    },
    DPD: {
      title: '일 생산량',
      unit: 'kg',
      // icon: <WaterDropIcon />,
    },
  },
};

export default ParameterInfo;

export const FirebaseConfig = ['sensors', 'managements', 'control', 'kpi'];
